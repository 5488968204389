.more_apps {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f9fbff;
    margin-right: -32px;
    margin-left: -32px;
    padding-top: 32px;
    padding-bottom: 32px;
}

.title {
    color: var(--success);
    font-size: 18px;
}

.sub_title {
    color: #2E3132;
    font-size: 38px;
    font-weight: 700;
}

.text {
    color: black;
    max-width: 550px;
}

.apps {
    display: flex;
    gap: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.certification {
    background-color: #1C4C75;
    color: white
}

.incentives {
    background-color: #E3EDFF;
    color: #001D34;
}

.app {
    text-align: left;
    padding:16px;
    border-radius: 8px;
    width: 440px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 20px;
    border-bottom: solid;
    border-bottom-width: 1px;
    padding-bottom: 16px;

}

.header :global(img) {
    height: 28px;
}

.body {
    font-size: 30px;
    font-weight: 700;
    line-height: 42.5px;
    padding-top: 16px;
    padding-right: 50px;
    padding-bottom: 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.action {
    display: flex;
    justify-content: end;
}

.action :global(a) {
    border-radius: 16px;
    width: 125px;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.certification :global(a) {
    background-color: white;
    color: #1C4C75;
}

.incentives :global(a) {
    background-color: #001D34;
    color: #FFFFFF;
}