.header {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 16px;
}

.container {
  overflow-y: auto;
  max-height: 350px;
  width: calc(100% - 32px);
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(34, 32, 32, 0.052);
  border-radius: 40px;
  padding: 25px;
  position: absolute;
}

.suggestion {
  padding: 5px 10px;
  display: inline-block;
  width: 95%;
  font-weight: 700;
}

.suggestion:hover {
  background-color: #d9ecf0;
  color: #02355a;
  cursor: pointer;
}
