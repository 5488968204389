.button-container {
  text-align: center;
}

.submit {
  font-size: 1.125rem;
  font-weight: bold;
  border-radius: 32px;
  padding: 10px 40px;
}

.caret {
  vertical-align: middle;
  border-left: 4px solid;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  margin-top: 10px;
  float: right !important;
}
