.page-container {
  max-width: 1440px;
  width: 100%;
  padding: 0;
  margin: auto;
  position: relative;
  overflow: hidden;
  background-color: #f9fbff;
}
.bg_img {
  position: absolute;
  bottom: -48.5rem;
  left: -2rem;
  height: 100%;
  width: 50%;
  background: url("@/assets/imgs/bg/bg_2.png") no-repeat center;
  background-size: contain;
  mix-blend-mode: normal;
  /* opacity: 0.5; */
  /* z-index: -3; */
}
.side-nav {
  display: none !important;
}
@media (max-width: 991px) {
  .page-container > aside,
  .page-container > main {
    grid-area: stack;
  }
  .side-nav {
    display: block !important;
  }
  .page-container {
    display: grid;
    grid: [stack] 1fr / min-content [stack] 1fr;
    min-height: 100vh;
    margin-bottom: -40px;
  }
  .bg_img {
    bottom: -82.5rem;
    left: -5rem;
    height: 134%;
    width: 157%;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
}
.page-content {
  flex-grow: 1;
  padding: 24px 0px;
  margin-top: 3rem;
}
.page-content > div {
  max-width: 100vw;
}

@media (min-width: 576px) {
  .page-content {
    padding: 24px 32px;
  }
}

@media (max-width: 991px) {
  .page-content {
    margin-top: 0;
  }
}
