.container {
  margin-top: 32px;
  padding: 32px 24px;
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(48, 49, 51, 0.05), 0 8px 16px rgba(48, 49, 51, 0.1);
}

.ai-addendum-and-resources {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  margin-bottom: 24px;
}

@media (min-width: 578px) {
  .ai-addendum-and-resources {
    flex-direction: row;
  }

  .ai-addendum-and-resources > * {
    flex: 1 1 50%;
  }
}
