.page-container {
  max-width: 1440px;
  width: 100%;
  padding: 0;
  margin: auto;
  position: relative;
  overflow: hidden;
  background-color: white;
}

.side-nav {
  display: none !important;
}
@media (max-width: 991px) {
  .page-container > aside,
  .page-container > main {
    grid-area: stack;
  }
  .side-nav {
    display: block !important;
  }
  .page-container {
    display: grid;
    grid: [stack] 1fr / min-content [stack] 1fr;
    min-height: 100vh;
    margin-bottom: -40px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
}
.page-content {
  flex-grow: 1;
  padding: 24px 0px;
  margin-top: 3rem;
}
.page-content > div {
  max-width: 100vw;
}

@media (min-width: 576px) {
  .page-content {
    padding: 24px 32px;
  }
}

@media (max-width: 991px) {
  .page-content {
    margin-top: 0;
  }
}
