.container {
  background: #fafbfd;
  border-radius: 5px;
  padding: 2.5rem 1rem;
  margin: 3rem auto 1rem auto;
}
.partner-text {
  color: #0b3860;
  font-size: 23px;
  text-align: center;
  width: 100%;
  margin: auto auto 2rem auto;
}
.partner-imgs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.partner-imgs img {
  max-height: 60px;
}
@media (max-width: 991px) {
  .container {
    height: auto;
  }
  .partner-imgs {
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 0;
    padding: 0;
    height: auto;
  }
  .partner-imgs img:not(:last-child) {
    margin-bottom: 3rem;
  }
  .partner-imgs img:last-child {
    max-height: 135px;
  }
  .partner-text {
    margin: 1.5rem auto;
    margin-bottom: 0;
  }
  .partner-text p {
    margin-bottom: 0;
  }
}

@media print {
  .partner-imgs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .partner-imgs img {
    margin-bottom: 0 !important;
  }
}
