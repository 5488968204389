.accordion :global .accordion-item {
  border: 1px solid #b3d3ff;
}

.accordion :global .accordion-button,
.accordion :global .accordion-body {
  padding-left: 16px;
  padding-right: 16px;
  background-color: #f0f3fa;
}

.accordion :global .accordion-button:after {
  margin-top: 3px;
  margin-left: 3px;
  width: 12px;
  height: 12px;
  background-size: 12px;
}
