.header {
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  color: #00568d;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 14px;
  align-items: center;
}

.icon {
  font-size: xxx-large !important;
  color: #0ba884;
}

.requested-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-left: 8rem;
  margin-right: 8rem;
}

.close-container {
  display: flex;
  justify-content: center;
}

.close-button {
  font-weight: bold;
  font-size: 1rem;
  padding-left: 64px;
  padding-right: 64px;
  border-radius: 20px;
  background-color: white !important;
  border: 3px solid var(--primary) !important;
  color: var(--primary) !important;
}

@media (max-width: 991px) {
  .row {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    align-items: stretch;
  }

  .requested-container {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
