.container {
  margin-top: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
}

.brand {
  padding: 0;
}
.circle {
  width: 130px;
  height: 130px;
  background: #f9fafd;
  border-radius: 50%;
  position: relative;
}
.circle img {
  position: absolute;
  width: 107px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}
.section h4 {
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  color: #0b3860;
}
.section p {
  font-size: 18px;
  line-height: 25px;
  margin: 0;
}
@media (max-width: 991px) {
  .container {
    height: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .circle {
    width: 100%;
    background-color: transparent;
    border-radius: 0;
  }
  .circle img {
    max-width: 100%;
    height: auto;
  }
}
