.btn {
  background-color: var(--success);
  border-radius: 24px;
  padding: 10px max(38px, 7%);
}
.btn span {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.btn:hover span {
  color: #fff;
}
