.container {
  background: #fafbfd;
  border-radius: 5px;
  padding: 24px;
}

.container tspan {
  fill: #255f86;
  font-weight: bolder;
  font-size: 15px;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.tooltip {
  padding: 5px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: #003863;
}

.legend {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  background-color: #f0f3fa;
  padding: 9px 18px;
  border-radius: 5px;
}

.bar {
  display: flex;
  width: 48px;
}

.bar > div {
  width: 50%;
  margin-left: 40%;
  height: 3px;
}

.name {
  padding-left: 10px;
  padding-right: 20px;
}
