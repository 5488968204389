.container {
  background: url("@/assets/imgs/humanRobot_small.png") no-repeat center;
  background-size: cover;
  position: relative;
  border-radius: 10px;
}

.content {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 5vh;
}
.text {
  /* responsive top padding to show clear image on big screen and decrease when we have many lines   */
  /* to show the image under in the small screen close values to figma design on width 1440px and 414px  */
  padding-top: calc(20.66vw - 39.5px);
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  font-weight: bold;
}
@media (min-width: 578px) {
  .container {
    background-image: url("@/assets/imgs/humanRobot.png");
  }
}
@media (min-width: 1400px) {
  .text {
    padding-top: 250px;
  }
}
.btn {
  position: relative;
  background: #003863;
  border-radius: 28px;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin-top: 15px;
  padding: 12px 48px 12px 24px;
  max-width: 95%;
}
.btn:hover {
  color: #ffffff;
}

.icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
