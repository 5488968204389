.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  width: 100%;
}

.header h1 {
  color: var(--primary);
  font-weight: 600;
  font-size: 1.625rem;
  margin-bottom: 0;
}

.new-button {
  width: 157px;
  height: 43px;
  background: #00568d;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 1rem;
}
.icon {
  background: #f0f3fa;
  margin-right: 1rem;
  padding: 16px 24px;
  width: 61px;
  height: 61px;
  background: #f0f3fa;
  border-radius: 45px;
}
.brand {
  display: none;
}
.left,
.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991px) {
  .header h1 {
    font-size: 1.4rem;
  }
  .icon {
    display: none;
  }
  .new-button {
    margin: 0;
    visibility: hidden;
    width: 0;
  }
  .new-button:after {
    content: "New";
    visibility: visible;
    position: absolute;
    width: 82px;
    height: 43px;
    line-height: 43px;
    left: 277px;
    top: 128.5px;
    background: #00568d;
    border-radius: 5px;
  }
  .left,
  .right {
    width: 100%;
  }
  .brand {
    display: block;
  }
  .right {
    margin-bottom: 1.5rem;
  }
  .header {
    flex-direction: column-reverse;
    padding: 1rem;
  }
}
