.Toastify__toast {
  font-family: "Montserrat", Sans-Serif;
}

.Toastify__close-button {
  display: inherit;
  align-self: center;
}
.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
  color: #0b8166;
}
.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
  color: #0b8166;
}
.Toastify__slide-enter--top-center {
  color: #0b8166;
}
.Toastify__slide-enter--bottom-center {
  color: #0b8166;
}
.Toastify__toast.Toastify__toast--error,
.Toastify__toast.Toastify__toast--success {
  background-color: white;
}
.Toastify__slide-enter--top-right,
.Toastify__toast--success {
  border: 2px solid var(--success);
  color: #0b8166;
}

.Toastify__toast--success .Toastify__close-button {
  color: var(--success);
  opacity: 1;
}

.Toastify__toast--error {
  border: 2px solid red;
  color: #c70606;
}

.Toastify__toast--error .Toastify__close-button {
  color: #c70606;
  opacity: 0.8;
}
