body {
  margin: 0;
  color: black;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f7f9;
  --primary: #003863;
  --success: #0fa884;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* restore the anchor tag reboot from bootstrap 4 by having links underlined only on hover */
a {
  text-decoration: none;
}

a:not(.btn):hover {
  text-decoration: underline;
}

.btn-block {
  display: block;
  width: 100%;
}

.form-group {
  margin-bottom: 1rem;
}
