.container {
  position: relative;
  margin-bottom: 3rem;
  justify-content: space-evenly;
}
.heading {
  color: var(--primary);
  font-weight: bold;
}
.hr {
  background-color: #ff0077;
  border-radius: 2rem;
  margin-left: 0;
  height: 0.3rem;
  width: 3.5rem;
}
.text-primary {
  color: var(--primary);
}
.text-danger {
  color: #ff0077;
}
.member-login {
  display: none;
  width: 100%;
  border-radius: 3rem;
  background-color: var(--primary);
  color: #fff;
  font-weight: bold;
}
.join-btn {
  border-radius: 3rem;
  background-color: var(--success);
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 1rem;
}
.join-btn:hover {
  color: #fff;
}
.learn-more-link {
  color: #ff0077;
  text-decoration: underline;
  font-weight: bold;
}
.learn-more-link:hover {
  color: #ff0077;
  z-index: 2;
}
.bg_img {
  height: 22rem;
  width: 11rem;
  z-index: 1;
  position: absolute;
  right: -18.5rem;
  bottom: -1.2rem;
}
@media (min-width: 576px) {
  .container {
    padding-right: 3rem;
  }
}
@media (max-width: 768px) {
  .join-btn {
    width: 100%;
    margin-bottom: 1rem;
    z-index: 2;
  }
  .member-login {
    display: block;
    margin-bottom: 1rem;
    z-index: 2;
  }
  .bg_img {
    height: 21rem;
    width: 11rem;
    position: absolute;
    right: -4.5rem;
    bottom: -10rem;
  }
  .container {
    overflow: hidden;
    margin-bottom: 2rem;
  }
}
