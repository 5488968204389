.container {
    background: #F9FAFD;
    border-radius: 8px;
    padding: 25px 10%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.info {
    display: flex;
    gap: 20px;
}