.partner-text {
  color: #0b3860;
  font-size: 23px;
  text-align: center;
  width: 100%;
  margin: 3rem auto;
}
.partner-imgs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
.partner-imgs img {
  height: 60px;
}

@media (max-width: 991px) {
  .partner-imgs {
    justify-content: center;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .partner-imgs img {
    margin-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 100vw;
  }
  .partner-text {
    margin: 1.5rem auto;
    margin-bottom: 0;
    max-width: 90vw;
  }
  .partner-text p {
    margin-bottom: 0;
  }
}
