.container {
  height: 384px;
  background: #ffffff;
  border-radius: 25px;
  padding: 1.5rem;
  margin-top: 1rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-content: space-around;
}

.header-container h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #00568d;
  margin-bottom: 0.8rem;
}

.col-12 div {
  padding: 1rem;
}

.col-12 p {
  margin: 0;
}

.col-12 div a {
  text-decoration: none !important;
  border-bottom: 1px solid;
  color: #1e90ff;
}

.col-6,
.col-12 {
  padding: auto 1rem;
}

.col-6 div,
.col-12 div {
  height: 85px;
  background: #f9fafd;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.col-6 p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.44px;
  color: #000000;
  margin: 0;
}

.col-6 span {
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  text-align: center;
}

@media (max-width: 991px) {
  .container {
    height: auto;
    /* padding: 1rem !important; */
  }

  .col-12 div {
    height: auto;
  }

  .col-6:last-child div {
    margin-bottom: 0;
  }
}
