.certification {
  background-color: #f5f6f8;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  height: 10rem;
  padding: 0;
  overflow: hidden;
  position: relative;
}
.left {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  height: 100%;
  width: 19rem;
}
.right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  height: 100%;
  width: 24rem;
}
.left img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.right img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.text {
  color: #006088;
  font-weight: bold;
  margin: auto;
  text-align: center;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.394167px;
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 65%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
  .text {
    font-size: 1.2rem;
    width: 85%;
  }
  .right {
    display: none;
  }
  .left {
    width: 100%;
    height: 50%;
    top: auto;
    bottom: 0;
  }
  .certification {
    height: 20rem;
  }
}
