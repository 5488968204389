/* customize the primary and success styles to match the mockups */
$primary: #003863;
$success: #0fa884;
/**
 * by setting $min-contrast-ratio to 3 the color of text in success is set to white
 * which is what we want. Keeping the default value as is result in a dark text color
 * for more details https://stackoverflow.com/a/68303393
 */
$min-contrast-ratio: 3;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
