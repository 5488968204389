.shadow > label {
  color: #00568d;
  font-weight: 600;
}

.shadow textarea,
.shadow input,
.shadow select {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.0520651);
  border-radius: 5px;
  color: black;
}
