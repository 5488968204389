.container {
  height: 137px;
  background: linear-gradient(271.57deg, #1e90ff -16.99%, #ff3278 132.86%);
  border-radius: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem auto;
}
.container p {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
.container h3 {
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
@media (max-width: 991px) {
  .container {
    border-radius: 5px;
    padding: 1.5rem;
  }
}
