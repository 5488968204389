.font-awesome {
  color: #ffffff !important;
  background-color: #00af7e !important;
  position: absolute;
  width: 35px !important;
  height: 35px;
  border-radius: 50% !important;
  right: 6px;
  padding: 10px;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
}

.full-address-search :global(.input-group) {
  position: relative;
}

.full-address-search input {
  border-radius: 60px !important;
  z-index: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.0520651);
  font-weight: 900;
  color: #000000 !important;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 22px;
  font-size: 1.125rem;
}
.full-address-search input::placeholder {
  font-size: 20px;
  color: #000000;
  opacity: 0.5;
}
