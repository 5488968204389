.button {
  border-radius: 3rem;
  background-color: var(--success);
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.text-primary {
  color: #00568d;
}

.links {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; 
}
.text-success {
  color: var(--success);
  font-size: 14px;
}
.card {
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  z-index: 2;
}
.title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: var(--primary)
}

.info {
  margin-top: 20px;
  color: #747878;
  text-align: center;
}
