.input-group-with-shadow label {
  color: #00568d;
  font-weight: 600;
}

.input-group-with-shadow input {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.0520651);
  border-radius: 5px;
  color: black;
}

.input-group-with-shadow .input-group-text {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.0520651);
  border-radius: 5px;
  font-size: 0.875rem;
  color: grey;
  background-color: white;
}

.input-group-with-shadow input + .input-group-text {
  clip-path: inset(-5px -5px -5px 0);
}

.input-group-with-shadow .input-group-text ~ input {
  clip-path: inset(-5px -5px -5px 0);
}
