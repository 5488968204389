.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.close {
  color: #ff3278;
  cursor: pointer;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
  text-align: center;
}

.text {
  color: #4a4a4a;
  text-align: center;
}

.load {
  border-radius: 20px;
  padding: 24px 32px;
}

.cancel {
  font-weight: bold;
  color: #ff3278;
  cursor: pointer;
  text-decoration: underline;
}
