.container {
  padding-top: 16px;
  padding-bottom: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.header h2 {
  margin-bottom: 0;
  color: var(--primary);
  font-size: 1.5rem;
  font-weight: bold;
}

.table-container {
  overflow-x: auto;
  /* the padding keeps the table border visible on all screen sizes */
  padding-left: 1px;
  padding-right: 1px;
  transition: max-height 2s ease;
  max-height: 2500px;
}

.table {
  margin-bottom: 0;
}

.table th,
.table td {
  border: none !important;
  text-align: center;
}

.table thead tr {
  color: white;
  background-color: var(--primary);
}

.table thead th:first-child {
  border-top-left-radius: 10px;
}

.table thead th:last-child {
  border-top-right-radius: 10px;
}

.table tbody {
  color: black;
  font-size: 1.25rem;
  border: 1px solid #cfcfcf !important;
}

.table tbody tr:nth-child(even) td {
  background-color: #f8fcff;
}

.expandable {
  max-height: 500px;
  overflow: hidden;
  position: relative;
}

.expand {
  position: absolute;
  bottom: 0;
  height: 200px;
  width: 100%;
  display: grid;
  place-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -40%, #ffffff 60%);
}

.expand button {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3), -1px -1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  padding: 6px 48px;
}
