.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.buttons button {
  font-weight: bold;
  font-size: 1rem;
  padding-left: 64px;
  padding-right: 64px;
  border-radius: 20px;
}

@media (max-width: 575px) {
  .buttons button {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.back:active,
.back:hover,
.back {
  background-color: white !important;
  border: 3px solid var(--primary) !important;
  color: var(--primary) !important;
}
