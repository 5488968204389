.noresults {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 200px;
}

.icon {
  margin-bottom: 18px;
}

.message {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 8px;
}

.address {
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;
  color: #00568d !important;
}

.start {
  padding: 8px 48px;
  font-weight: bold;
  border-radius: 24px;
  margin-bottom: 32px;
}
