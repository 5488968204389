.button {
  border-radius: 3rem;
  background-color: var(--primary);
}
.text-primary {
  color: #00568d;
}
.text-danger {
  color: #ff0077;
}
.card {
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  z-index: 2;
}
