.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 16px;
}

.container > * {
  margin: 0;
}

.title {
  color: var(--primary);
  font-size: 1.5rem;
  font-weight: bold;
}

.light-button,
.button {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 32px;
}

.light-button {
  color: var(--primary);
  background-color: #eaf3fb;
}

.learn:hover,
.learn {
  color: #ff0077;
  text-decoration: underline;
  font-size: 1.25rem;
  font-weight: bold;
}
