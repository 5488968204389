.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 24px 16px;
  border-radius: 20px;
  background: rgba(234, 243, 251, 0.3);
}

.container h2 {
  color: var(--primary);
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
}

.button:hover,
.button {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  color: #ff3278;
  font-weight: bold;
  background-color: #eeeff3;
}
