.header {
  flex-grow: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: #00568d;
  font-size: 1.375rem;
  font-weight: bold;
}

.toggle {
  color: #1f5b7a;
}
