.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
}

.logo {
  width: 100px;
  height: auto;
}

.close {
  color: var(--primary);
  cursor: pointer;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .close {
    display: none;
  }
}

.title,
.apps {
  margin-bottom: 16px;
  color: #5c5c5c;
  font-size: 1rem;
  font-weight: 700;
}

.apps {
  margin-top: 32px;
}
