.container {
  min-height: 3rem;
  display: flex;
  flex-direction: column;
}
.text {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}
.actions {
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  width: 40%;
}
.copy {
  font-weight: 900;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #919191;
  margin-top: 3rem;
  display: none;
}
.copy p {
  margin: 0;
}

@media (max-width: 991px) {
  .actions {
    width: 80%;
  }
  .copy {
    display: block;
  }
}

@media print {
  .text {
    display: block;
  }
}
