.wrapper {
  max-width: 300px;
  padding: 16px;
  margin: auto;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.0520651);
  border-radius: 40.5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper > span {
  text-align: center;
  word-wrap: break-word;
}

.wrapper:hover > span {
  color: #ffffff;
}

.wrapper:hover {
  background: #003863;
}

.view-by {
  font-size: 18px;
  font-weight: bold;
}
