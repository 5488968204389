@media only screen and (min-width: 576px) {
  .headerContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.flex-container {
  display: flex;
  align-self: flex-start;
  justify-content: space-evenly;
  margin-top: 32px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(283px, 100%), 1fr));
  grid-gap: 16px;
  align-items: center;
}

.link {
  text-decoration: none !important;
}
