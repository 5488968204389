#sidenav-open {
  --easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
  --duration: 0.6s;
  width: 300px;
  display: grid;
  grid-template-columns: [nav] 1fr [escape];
  z-index: 1000;
}

@media (max-width: 991px) {
  #sidenav-open {
    width: auto;
    grid-template-columns: [nav] 2fr [escape] 1fr;
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow: hidden auto;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    visibility: hidden;
    transform: translateX(-110vw);
    -ms-transform: translateX(-110vw);
    -webkit-transform: translateX(-110vw);
    -moz-transform: translateX(-110vw);
    -o-transform: translateX(-110vw);
    will-change: transform;
    transition: transform var(--duration) var(--easeOutExpo),
      visibility 0s linear var(--duration);
  }

  #sidenav-open:target {
    visibility: visible;
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transition: transform var(--duration) var(--easeOutExpo);
  }
}

@media (max-width: 575px) {
  #sidenav-open {
    grid-template-columns: [nav] 20fr [escape] 1fr;
  }
}

#sidenav-button,
#sidenav-close {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

@media (min-width: 992px) {
  #sidenav-button,
  .sidenav-open,
  #sidenav-close {
    display: none !important;
  }
}

#sidenav-open nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  background-color: #f9fbff;
}

@media (max-width: 991px) {
  #sidenav-open nav {
    box-shadow: 5px 0 40px rgba(0, 0, 0, 0.45);
    font-size: 1.5rem;
  }
}

#sidenav-open nav .sidebar-content > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

#sidenav-open nav .sidebar-footer > * {
  margin-left: 1rem;
  margin-right: 1rem;
}

@media (min-width: 576px) {
  #sidenav-open nav .sidebar-content > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  #sidenav-open nav .sidebar-footer > * {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.sidebar-copyright {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #919191;
  font-size: 0.875rem;
}
