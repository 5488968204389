.card {
  position: relative;
  z-index: 4;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
.button {
  border-radius: 3rem;
  background-color: var(--primary);
}
.text-primary {
  color: #00568d;
}
.text-danger {
  color: #ff0077;
}

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 3;
}

/* The Close Button */
.close-modal {
    position: absolute;
    top: 7px;
    right: 10px;
    z-index: 5;
    font-size: 1.2rem;
}

.messages{
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
}