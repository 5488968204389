.container {
  background: #fafbfd;
  border-radius: 10px;
  padding: 16px;
}

.container > :last-child {
  margin-bottom: 0;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
}

.text {
  font-size: 1.125rem;
}
