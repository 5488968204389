.bills-table {
  line-height: 16px;
}
.bills-table th {
  font-weight: 700;
  font-size: 20px;
  color: #00568d;
}
.bills-table td {
  padding-right: 16px;
}
.bills-table div {
  margin-bottom: 0;
}
