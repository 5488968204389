.datepicker :global .react-datepicker-wrapper {
  display: block;
}

.datepicker :global .react-datepicker__month .react-datepicker__month-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.datepicker :global .react-datepicker__navigation-icon {
  top: 5px;
}
