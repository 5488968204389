.step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.step:hover {
  text-decoration: none !important;
}

.number {
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #1f5b7a;
}

.text {
  display: none;
  font-size: 1rem;
  font-weight: bold;
  color: #003863;
}

.step.active .number {
  background-color: #1e90ff;
}

.step.active .text {
  color: #1e90ff;
}

.text.visited {
  color: #6b6b6b;
}

.number.visited {
  background-color: #0ba884;
}

@media (min-width: 768px) {
  .text {
    display: block;
  }

  .number {
    margin-right: 12px;
  }
}
