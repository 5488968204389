.specific_utility-container {
  align-items: end;
}
.hiden-label-container label {
  visibility: hidden;
}
.link-container {
  margin-bottom: 20px;
}

.link {
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.286667px;
  text-decoration-line: underline;
  color: #1e90ff;
  cursor: pointer;
}
