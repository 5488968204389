.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #2e3132;
}

.rate_range {
  background: linear-gradient(271.57deg, #0ba884 -16.99%, #1e90ff 132.86%);
  border-radius: 100px;
  max-width: 300px;
  padding: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.rate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rate-1 {
  padding-top: 0px;
}

.rate-2 {
  padding-top: 50px;
}
.rate-3 {
  padding-top: 100px;
}

.line {
  background-image: linear-gradient(black 50%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 4px 18px;
  background-repeat: repeat-y;
  width: 10px;
  flex-grow: 1;
}

.rate_value {
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #000000;
  margin-top: 4px;
}

.rates_container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px;
  height: 325px;
  margin-top: 20px;
}

@media screen and (max-width: 1199px) {
  .rates_container {
    display: none;
  }
}

/* mobile */
.rates_container_mobile {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1200px) {
  .rates_container_mobile {
    display: none;
  }
}
.tabs {
  display: flex;
  border-bottom: 1px solid #c4c7c7;
  margin-top: 28px;
  cursor: pointer;
  gap: 8px;
}

.tab {
  flex-grow: 1;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #747878;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.tab.active {
  color: #1c4c75;
}

.active .tab_line {
  background-color: #1c4c75;
  background-repeat: no-repeat;
  background-size: 3px 50px;
  height: 3px;
  width: 50%;
}

.content {
  font-weight: 500!important;
  font-size: 16px!important;
  line-height: 140%!important;
}
