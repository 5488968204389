.spinner {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: grey;
}

.text {
  margin-left: 4px;
  font-size: 0.75rem;
}
