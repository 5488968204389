.font-awesome {
  color: #0ba884 !important;
  position: absolute;
  right: 24px;
  top: calc(50% - 16px);
  z-index: 3;
}

.full-address .input-group {
  position: relative;
}

.full-address input {
  border-radius: 40px !important;
  z-index: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.0520651);
  font-weight: 900;
  color: #000000;
  --padding: 24px;
  padding: var(--padding);
  --font-size: 1.25rem;
  font-size: var(--font-size);
  height: calc(2 * var(--padding) + 1.5 * var(--font-size));
}

.full-address label {
  font-size: 1.5rem;
}
