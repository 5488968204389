.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
}

.headerContainer > * {
  margin-bottom: 0;
}

.header {
  color: var(--primary);
  font-weight: 600;
  font-size: 1.5rem;
}
