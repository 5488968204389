.page-container {
  display: grid;
  grid: [stack] 1fr / min-content [stack] 1fr;
  min-height: 100vh;
}

@media (max-width: 991px) {
  .page-container > aside,
  .page-container > main {
    grid-area: stack;
  }
  .main {
    padding: 0.5rem 0 0 0 !important;
    overflow: hidden;
  }
}

.main {
  padding: 0.5rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .main {
    padding: 0.5rem 1.5rem 0 1.5rem;
  }
}

.page-content {
  flex-grow: 1;
  padding: 24px 16px;
  border-radius: 10px 10px 0 0;
  box-shadow: 15px 4px 55px rgba(0, 0, 0, 0.05), -15px 22px 55px rgba(0, 0, 0, 0.05);
  background-color: white;
}

@media (min-width: 576px) {
  .page-content {
    padding: 24px 32px;
  }
}

@media print {
  .page-content {
    padding: 0;
  }
}
