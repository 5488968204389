.vr {
  width: 1px;
  height: 70px;
  border-right: 2.5px solid lightgrey;
  margin: auto 1.5rem;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand {
  width: 5rem;
}
.header h2 {
  color: #003863;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px 37px 0px 37px;
}
.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max(230px, 25%);
}



@media (max-width: 991px) {
  .header h2 {
    font-size: 15px;
    line-height: 18px;
  }
  .vr {
    margin: auto 1rem;
  }
  .row {
    padding: 16px 15px;
  }
}
