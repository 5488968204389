.table thead tr th {
  border-top: none;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #fafbfd;
}

.table tbody tr td:nth-of-type(even) {
  font-weight: bold;
}
