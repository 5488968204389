.container {
  padding: 24px 16px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 16px;
}

.link:hover,
.link {
  color: #1e90ff;
  font-weight: bold;
  text-decoration: underline;
}
