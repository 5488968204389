.certify-container {
  border-radius: 192px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 6px 12px;
}

.certify-container h5 {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 2px;
  margin-left: 4px;
}

.icon {
  color: #ffffff;
}

.cert-requested {
  background: #0ba884;
}

.no-cert-requested {
  background: #0b3860;
}
