.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
}

.delete {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #d00000;
  cursor: pointer;
  font-size: 0.875rem;
  border: none;
  background: transparent;
}

.delete[disabled] {
  color: #efa5a5;
}

.delete[disabled]:hover {
  cursor: not-allowed;
}

.delete > :first-child {
  margin-right: 4px;
}
