.hamburger {
  display: flex;
  place-content: center;
  padding: 1rem;
  border-radius: 50%;
  border: 2px solid transparent;
  outline: none;
  transition: border-color 0.25s ease, background-color 0.25s ease;
}

.hamburger:hover {
  border-color: rgba(31, 91, 122, 0.5);
}

.hamburger:focus {
  border-color: rgba(31, 91, 122, 0.12);
  background: rgba(31, 91, 122, 0.1);
}

.hamburger:active {
  background: rgba(31, 91, 122, 0.2);
}

.hamburger > svg {
  width: 28px;
  height: 28px;
}

.hamburger > svg > line {
  stroke: #1f5b7a;
  stroke-width: 7px;
  transition: stroke 0.25s ease;
}
