@media print {
  body {
    background: transparent !important;
  }

  .hide-when-printing {
    display: none !important;
  }

  .avoid-page-break-inside {
    page-break-inside: avoid;
  }
}
