.actionsBtn {
  min-width: 100px;
  height: 35px;
  background: #eeeff3;
  border-radius: 65px;
  color: #1e90ff;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.actionsBtn:hover {
  color: #1e90ff;
}
.downloadImg {
  margin-right: 8px;
}
