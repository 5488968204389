.footer {
  height: 314px;
  background-color: #1c4c75;
  padding-bottom: 4rem;
  max-width: 1440px;
  margin: auto;
}
.button {
  border-radius: 3rem;
  background-color: #1c4c75;
  color: #fff;
  border-color: #fff;
}
.brand {
  width: 120px;
  margin-bottom: 1rem;
}
@media (max-width: 991px) {
  .footer {
    padding-bottom: auto;
    height: auto;
    position: relative;
  }
  .brand {
    width: 80px;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .button {
    border-radius: 3rem;
    background-color: #1c4c75;
    color: #fff;
    border-color: #fff;
    align-self: flex-start;
    position: absolute;
    right: 2rem;
    top: 3rem;
  }
}
