.title {
  font-size: 30px;
  line-height: 36px;
  color: #00ab82;
  font-weight: bold;
  margin: 10px;
  margin-bottom: 30px;
}
.container {
  margin: 10px;
}
@media (min-width: 768px) {
  .container {
    margin-right: 1vw;
  }
}
.sections {
  display: flex;
  flex-direction: column;
}
.sections > div {
  margin-top: 10px;
  margin-bottom: 10px;
}
