.container {
  /* height: 338px; */
  background: #fafbfd;
  border-radius: 5px;
  padding: 1.5rem;
}
.container p {
  margin: 0;
  font-size: 21px;
  line-height: 25px;
}
.container a {
  text-decoration: underline;
  color: #ff3278;
}
.container h2 {
  font-weight: bold;
  font-size: 35px;
  line-height: 44px;
  color: #1f5b7a;
}
.col-3,
.col-9 {
  padding: 0;
}
.col-3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-3 img {
  height: 145px;
  width: 145px;
}
.col-9 h2 {
  margin-bottom: 1.2rem;
}
@media (max-width: 991px) {
  .container h2 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .col-3 img {
    height: 150px;
    width: 150px;
    margin-top: 1rem;
  }
}
