.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 6px;
}

.top-paragraph {
  font-weight: bold;
  font-size: 17px;
  line-height: 15px;
  max-width: 200px;
  text-align: center;
}

.table-tilt {
  border-collapse: collapse;
  text-align: left;
}

.table-tilt-data {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  color: #fff;
  font-weight: 700;
}
.table-tilt-data:last-child {
  border-right: 0;
}

.tbody-table-tilt-row:nth-child(odd) {
  background: rgba(196, 196, 196, 0.1);
}

.th-tilt {
  font-size: 14px;
  line-height: 17px;
}

.td-tilt {
  font-size: 15px;
  line-height: 18px;
}
