.container {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.container p {
  margin: 0;
  font-size: 19px;
  line-height: 140.8%;
}
.container h2 {
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  color: #ff3278;
}
.container a {
  text-decoration: underline;
  color: #1f5b7a;
}
.p1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #1f5b7a;
}
.p2 {
}
@media (max-width: 991px) {
  .container {
    margin: 2rem auto;
  }
  .p1 {
    margin-bottom: 1rem !important;
  }
}

@media print {
  .container {
    margin: 0;
  }
}
