.container {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.102819);
  border-radius: 25px;
  padding: 20px;
  display: flex;
  max-width: 90vw;
  align-items: center;
  justify-content: space-around;
}
.container > div {
  margin: 2px 10px;
}
@media (min-width: 576px) {
  .container {
    border-radius: 48px;
    max-width: 768px;
    padding-right: 32px;
  }
}
.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #0b3860;
  margin-bottom: 5px;
}
.description {
  font-size: 16px;
  line-height: 19px;
  color: #252525;
}
