.card {
  position: relative;
  padding: 16px 16px;
  border-radius: 5px;
  background-color: #f9fbff;
}

@media (min-width: 576px) {
  .card {
    padding: 16px 32px;
  }
}
