.dropdown button {
  background: #f0f3fa !important;
  border-radius: 30px;
  padding: 16px 24px;
  font-weight: 500;
  color: var(--primary) !important;
}

.dropdown .dropdown-menu {
  width: 100%;
  border-radius: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.dropdown-menu :global(.dropdown-item:hover) {
  text-decoration: none;
}

.desktop {
  display: none;
}

@media (min-width: 992px) {
  .desktop {
    display: inline-block;
  }
}

.mobile {
  display: inline-block;
}

@media (min-width: 992px) {
  .mobile {
    display: none;
  }
}
