.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.link > div {
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  margin-right: 24px;
}

.link a {
  color: #5c5c5c;
  font-size: 1rem;
  font-weight: 600;
}

.link.active {
  border-left: 5px solid var(--primary);
}

.link.active a {
  color: var(--primary);
  text-decoration: none;
  cursor: default;
}
