.container {
  position: relative;
  --input-height: calc(1.5em + 0.75rem + 2px);
  --input-padding: 0.375rem 0.75rem;
}

.input {
  display: block;
  width: 100%;
  height: var(--input-height);
  padding: var(--input-padding);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
}

.inputFocused {
  outline: none;
}

.suggestionsContainer {
  display: none;
  overflow: auto;
  max-height: 250px;
}

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  top: calc(var(--input-height) + 4px);
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-radius: 5px;
  font-size: 1rem;
  z-index: 2;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: var(--input-padding);
}

.suggestion:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.suggestion:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.suggestionHighlighted {
  background-color: #ddd;
}
