.container {
  max-height: 313px;
  background: #ffffff;
  border-radius: 25px;
  padding: 2rem 1.5rem 0rem 1.5rem;
  position: relative;
}
.quote {
  position: absolute;
  top: -15px;
}
.quote_line {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #0b3860;
  margin: 0;
}
.user {
  display: flex;
  align-items: center;
}
.user img {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  padding: 1.2rem;
}
.user p {
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #0b3860;
  margin: 0;
}
.user span {
  font-size: 15px;
  color: #0b3860;
}
