.container {
  min-height: 100vh;
}

.logo {
  display: grid;
  place-items: center;
  height: 180px;
}

.logo img {
  max-width: 100px;
  height: auto;
}

.content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 15px 4px 55px rgba(0, 0, 0, 0.05), -15px 22px 55px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  min-height: calc(100vh - 180px);
}

.centered {
  display: grid;
  place-items: center;
  grid-gap: 24px;
  min-height: calc(100vh - 180px - 2 * 20px);
  border-radius: 5px;
  background: #fafbfd;
  padding: 20px 0;
  color: #1f5b7a;
}

.centered h1 {
  max-width: 600px;
  text-align: center;
  font-weight: bold;
  font-size: 44px;
}

.centered h3 {
  max-width: 500px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.centered .button {
  background: #ffffff;
  border-radius: 45px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  padding: 16px 40px;
  border: none;
  font-weight: bold;
  color: #1f5b7a;
}

.back-arrow {
  margin-right: 10px;
}

.here {
  border: none;
  background: none;
  text-decoration: underline;
  font-weight: bold;
  color: #1f5b7a;
}
