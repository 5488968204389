.container {
  border-radius: 5px;
  padding: 1rem;
  padding-bottom: 0.8rem;
}
.heading {
  font-weight: bold;
  font-size: 21px;
  line-height: 30px;
  color: #00568d;
  margin: 0;
}
.hr {
  margin-top: 2rem;
}
.child {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions button {
  margin-left: 1rem;
}
.disclaimer {
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
}
@media (max-width: 991px) {
  .child {
    justify-content: center;
  }
  .container {
    height: auto;
  }
  .actions {
    margin-top: 1rem;
  }
  .hr {
    display: none;
  }
}
