.statistics {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;
  margin-top: 36px;
}

.statistics > div {
  padding: 1rem;
  min-width: 264px !important;
}

.statistics > div:first-of-type {
  margin-right: 24px;
}

@media (max-width: 700px) {
  .statistics > div {
    padding: 1rem;
    min-width: 250px !important;
  }

  .statistics > div:first-of-type {
    margin-right: 40px !important;
  }
}

.registration {
  color: var(--primary);
  font-weight: bold;
}
.member-login {
  display: none;
  width: 100%;
  border-radius: 3rem;
  background-color: var(--primary);
  color: #fff;
  font-weight: bold;
}
@media (max-width: 992px) {
  .member-login {
    display: block;
    margin-bottom: 1rem;
    z-index: 2;
  }
}
