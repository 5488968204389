.back-homes {
  margin: 16px;
  font-weight: bold;
  font-size: 16px;
  color: #00af7e !important;
  text-align: center;
}

.back-homes:hover {
  color: #0c8568 !important;
}

.back-homes > span {
  cursor: pointer;
}

@media only screen and (min-width: 576px) {
  .back-homes {
    text-align: start;
  }
}
