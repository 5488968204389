.link {
  color: #ff3278;
  font-weight: bold;
  text-decoration: underline;
}

.link-open-modal {
  color: #0d6efd;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
}

.mr-1{
  margin-right: 4px
}
