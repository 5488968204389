.container {
  height: 418px;
  max-height: 100vh;
  background: url("@/assets/imgs/manWorks.png") no-repeat center;
  background-size: cover;
  position: relative;
}
@media (min-width: 576px) {
  .container {
    border-radius: 10px;
  }
}
.content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  margin: 40px 30px;
  padding: 10px;
}
.text {
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
}
.btn {
  position: relative;
  background: #ff0077;
  border-radius: 28px;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin-top: 15px;
  padding: 12px 48px 12px 24px;
  white-space: nowrap;
  max-width: 100%;
}
.btn:hover {
  color: #ffffff;
}

.icon {
  position: absolute;
  right: 16px;
}
