.note {
  padding: 16px 24px;
  border-radius: 5px;
  background-color: #f1f2f5;
}

.title {
  color: var(--primary);
  font-size: 1.5rem;
  font-weight: 600;
}

.text {
  color: black;
  font-size: 1.25rem;
}
