.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border-radius: 5px;
  background-color: #f3f8fd;
}

@media (min-width: 578px) {
  .container {
    flex-direction: row;
  }
}

.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.star {
  color: #0fa884;
}

.text {
  color: var(--primary);
  font-size: 1.25rem;
  font-weight: 500;
}

.button {
  font-size: 1.25rem;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
  border-radius: 24px;
  white-space: nowrap;
}
