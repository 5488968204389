/* Overriding tooltip container class  */
.pvsystem-owf-tooltip {
  background: #353535;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.content {
  --main-paragraph-color: #ffffff;
  font-weight: 700;
  color: var(--main-paragraph-color);
}

.content a {
  color: var(--main-paragraph-color);
}
