.number {
  font-size: 3.8rem;
  color: #0b3860;
}

.text {
  font-size: 1rem;
  /* responsive letter spacing 5/576*100 = 0.8681 */
  letter-spacing: 0.8681vw;
  color: #0b3860;
  margin-left: 12px;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .text {
    letter-spacing: 5px;
  }
}

.icon {
  color: #0ba884 !important;
  width: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}

.loading,
.error {
  text-align: center;
  padding: 10px;
}

.center {
  text-align: center;
}
