.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 5px;
  background-color: #fafbfd;
}

@media (min-width: 576px) {
  .steps {
    padding: 24px;
  }
}

.steps > * {
  margin-right: 8px;
}

@media (min-width: 576px) {
  .steps > * {
    margin-right: 16px;
  }
}

.steps > :last-child {
  margin-right: 0;
}

.separator {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  max-width: 24px;
  min-width: 4px;
  flex-grow: 1;
  border-top: 2px solid #0b3860;
}
