.form-group-container {
  padding-top: 18px;
}
.form-group-container .form-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px 8px;
}
.form-group-container label {
  min-width: min(400px, 100%);
  flex-grow: 1;
  margin: 4px 8px;
}
.form-group-container .form-group .react-datepicker-wrapper {
  flex-shrink: 100;
  width: 150px !important;
  flex-grow: 1;
  max-width: min(300px, 100%);
  margin: 4px 8px;
}
