.bg_none {
  background-color: transparent;
  border-color: transparent;
}
.close-modal {
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 5;
  font-size: 1.2rem;
}
