.loading {
  height: 600px;
  display: grid;
  place-items: center;
  place-content: center;
  grid-gap: 32px;
}

.text {
  color: #1e90ff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.loading img {
  max-width: 100%;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
